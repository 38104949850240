import React from 'react';
import mailtoLink from 'mailto-link';

// import { SubscribeModal } from 'gatsby-theme-newsletter/src/components/SubscribeModal';
import CallToAction from 'gatsby-theme-newsletter/src/components/CallToAction';

import Layout, { Block, Hero, ImageBlock, NarrowBlock } from '../../../components/Layout';
import SEO from '../../../components/Seo';
import { Faq, PricePlan } from '../../../components';

import styles from './index.module.scss';
import { ImageAlignment } from '../../../components/Layout/components/ImageBlock/ImageBlock';
import MockupPhone from '../../../components/MockupPhone';

const Video = (
  <MockupPhone>
    <img
      className={styles.screen}
      src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/medical-devices/videos/qrpid_medical-devices_screenrecording_01.gif"
    />
  </MockupPhone>
);

// const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
//   <CallToAction target="/de/qrpid-waiter-lockdown-angebot/demo">{children}</CallToAction>
// );

const subject = 'QRpid für medizinisches Gerät: Termin für Demo vereinbaren';
const body =
  'Guten Tag!\n\n\nIch möchte gerne einen Termin für eine Demo vereinbaren. Meine Kontaktdaten lauten wie folgt:\n\n\nFirma:\n\nAnsprechpartner:\n\nTelefonnummer:\n\n\n\nMit freundlichen Grüßen';

const mailto = mailtoLink({ subject, body, to: 'support@qrpid.io' });

const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
  <CallToAction target={mailto}>{children}</CallToAction>
);

const pricePlans: any = {
  basic: {
    name: 'Basic',
    price: '199',
    currency: '€',
    condition: 'pro Monat / zzgl. MwSt.',
    promotion: 'Aktion: 250 QR-Code Aufkleber gratis',
    cta: <DemoCTA>Demo anfordern</DemoCTA>,
    benefits: [
      'Bis zu 50 Geräte/Inventare verwalten',
      'Bis zu 20 Ansprechpartner verwalten',
      '1 Niederlassung verwalten',
      '1 Admin User',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
  pro: {
    name: 'Pro',
    price: '299',
    currency: '€',
    condition: 'pro Monat / zzgl. MwSt.',
    promotion: 'Aktion: 250 QR-Code Aufkleber gratis',
    cta: <DemoCTA>Demo anfordern</DemoCTA>,
    benefits: [
      'Bis zu 100 Geräte/Inventare verwalten',
      'Bis zu 50 Ansprechpartner verwalten',
      'Bis zu 3 Niederlassungen verwalten',
      '3 Admin User',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
  custom: {
    name: 'Custom',
    price: '?',
    condition: '-',
    cta: <CallToAction>Demo buchen</CallToAction>,
    benefits: [
      'Bis zu ? Geräte/Inventare verwalten',
      'Bis zu ? Ansprechpartner verwalten',
      'Bis zu ? Niederlassungen verwalten',
      '3 Admin User',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
};

const faqs = [
  {
    question: 'Woher bekomme ich die QR Code Aufkleber für meine Geräte?',
    answer:
      'Der einfachste Weg ist, hochwertige Aufkleber bei uns zu bestellen. Diese sind bereits mit individuellen QR Codes bedruckt und sofort einsatzbereit. Grundsätzlich können Sie Ihre QR Codes natürlich auch selbst drucken und aufkleben.',
  },
  {
    question: 'Was ist, wenn ein QR Code Aufkleber versehentlich unbrauchbar wird?',
    answer:
      'Wir liefern jeden unserer QR Code Aufkleber in dreifacher Ausfertigung. Das bietet genügend Spielraum für Fehlversuche.',
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IndexPage = () => (
  <Layout>
    <SEO
      title="Medizinisches Gerät mit QR-Codes verwalten"
      description="Verwalten Sie Ihre medizinischen Produkte mit den Next Level QR-Codes von QRpid."
      keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
    />
    <Hero video={Video}>
      <h1>Pflegen Sie alle Produktdaten zentral und teilen Sie diese global.</h1>
      <p>
        Mit unseren praktischen QR Code Aufklebern und der cloud-basierten Verwaltungssoftware
        erfassen Sie alle Geräte im Handumdrehen. Bekleben, scannen und zuweisen. So schnell geht
        das.
      </p>
      <DemoCTA />
    </Hero>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Geben Sie Ihren Geräten eine digitale Identität.</h1>
        <p>
          Von der Herstellung, zur Distribution und anschließenden Auflieferung an den Endkunden:
          meist schlummert der detaillierte Lebenslauf eines Gerätes in verschiedensten Daten-Silos.
          Wäre es nicht schön, wenn diese Informationen zur Qualitätssicherung, zum Vertrieb und
          anschließend beim Kunden genutzt werden könnten?
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/landing-pages/medical-device-manufacturers/qrpid_medical-device-manufacturers_benefits_01.png"
        alignment={ImageAlignment.RIGHT}
        alt="Geräteübersicht durch QR Codes"
        className="text-left"
      >
        <h3>Digitalisierung per QR-Code.</h3>
        <ul>
          <li>Der individuelle QR-Code stellt eine Verbindung zwischen Gerät und Web App her.</li>
          <li>
            Die Anzeige der Informationen passt sich dem aktuellen Funktionsmodus an, welchen Sie
            eingestellt haben (z.B. Lead-Generierung, Wartung oder Betrieb).
          </li>
          <li>
            Partner können freigegebene Bereiche ebenfalls aktualisieren, um z.B.
            Bearbeitungsfortschritte im Service zu hinterlegen.
          </li>
          <li>
            Durch Zugriffsstatistiken können Sie erkennen, wann auf Geräteinformationen zugegriffen
            wird.
          </li>
          <li>
            Sie entscheiden, welche Informationen öffentlich sind und für welche eine vorherige
            Autorisierung notwendig ist.
          </li>
        </ul>

        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps}>
      <NarrowBlock className="text-center">
        <h1>Vereinheitlichen Sie Ihre Produktinformationen.</h1>
        <p>
          Was bringt es, wenn Produktinformationen in Intranets und persönlichen Dokumenten
          versteckt bleiben? Nur wenn Partner und Kunden selbständig darauf zugreifen können, werden
          Sie als Vermittler dieser Informationen entlastet.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/landing-pages/medical-device-manufacturers/qrpid_medical-device-manufacturers_benefits_02.png"
        alt="Informationen zum Gerät mittels Web App"
        className="text-left"
      >
        <h3>Schluss mit Copy &amp; Paste!</h3>
        <ul>
          <li>Erfassen Sie alle produktrelevanten Daten in einer einheitlichen Oberfläche.</li>
          <li>
            Speichern Sie hochauflösende Produktfotos, welche dann automatisch auf das jeweilige
            Zielmedium zugeschnitten werden. So entfällt das händische Anpassen von Bildern.
          </li>
          <li>Erstellen Sie Antworten auf häufig gestellte Fragen (FAQ).</li>
          <li>Verknüpfen Sie Videos, Links und Dokumente (Handbücher, Broschüren etc.).</li>
          <li>Veröffentlichen Sie aktuelles und wissenswertes direkt am Produkt.</li>
          <li>Binden Sie weitere Systeme über unsere Schnittstelle (API) an.</li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Unterstützen Sie Ihre Kunden.</h1>
        <p>
          Welche Person ist auf welches Gerät eingewiesen? Wo ist das Gerätehandbuch zum Gerät
          abgelegt? Und wann ist welcher Service fällig? Beim Kunden ist der Nutzen der QR-Codes
          noch lange nicht am Ende.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/landing-pages/medical-device-manufacturers/qrpid_medical-device-manufacturers_benefits_03.png"
        alignment={ImageAlignment.RIGHT}
        alt="Digitalisierung der Dokumentation"
        className="text-left"
      >
        <h3>SUPPORT wird ab sofort groß geschrieben.</h3>
        <ul>
          <li>
            Helfen Sie Ihren Kunden, sich selbst zu helfen. Aktuelle Bedienungsanleitungen, häufig
            gestellte Fragen und Erklärvideos sollten immer zur Hand sein.
          </li>
          <li>
            Ersparen Sie Ihren Kunden die Suche nach Visitenkarten. Halten Sie stattdessen die Daten
            und Zuständigkeiten der virtuellen Visitenkarten Ihrer Mitarbeiter aktuell.
          </li>
          <li>
            Zeigen Sie im Kontaktformular den richtigen Ansprechpartner zum aktuellen Anliegen.
          </li>
          <li>Durch guten Support fühlen sich Ihre Kunden wertgeschätzt.</li>
          <li>
            Erhöhen Sie die Anzahl der positiven Kundenkontakte, um diese vertrieblich zu nutzen.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>30 Tage Geld-zurück-Garantie.</h1>
        <p>
          Wenn Sie innerhalb des ersten Monats kündigen, erhalten Sie den gesamten Betrag zurück.
          Ohne Angabe von Gründen!
        </p>
      </NarrowBlock>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <div className={styles.pricePlans}>
              <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.basic} />
              <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.pro} />
              {/* <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.custom} /> */}
            </div>
          </div>
        </div>
      </div>
      <NarrowBlock className={`text-center`}>
        <h3>
          Einfache Einrichtung.
          <br />
          Vorhandene Ausstattung nutzen.
          <br />
          Monatlich kündbar.
        </h3>
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1 className="text-center">Häufig gestellte Fragen.</h1>
        <Faq faqs={faqs} />
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Neugierig geworden?</h1>
        <p>
          Dann machen Sie ganz einfach über unser Formular einen Termin mit uns aus. Wir melden uns
          dann schnellstmöglich und besprechen die weiteren Schritte.
        </p>
        <div>
          <DemoCTA>Termin für Demo vereinbaren</DemoCTA>
        </div>
      </NarrowBlock>
    </Block>
  </Layout>
);

export default IndexPage;
